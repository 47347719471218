body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.fa-star,
.fa-star-o {
  font-size: 1.5rem;
  color: red;
}

.clickable {
  cursor: pointer;
}

table {
  table-layout: fixed;
}

.alert-success {
  margin-top: -1rem;
}

.react-date-picker__inputGroup span,
.react-date-picker__inputGroup input,
.react-date-picker__inputGroup__divider,
.react-datetime-picker__inputGroup span,
.react-datetime-picker__inputGroup input,
.react-datetime-picker__inputGroup__divider {
  color: #007bff !important;
}
